<template>
  <div>
    <a-button-group>
      <a-button-group size="small">
        <a-button type="danger" size="small" @click="removeItem">移除</a-button>
      </a-button-group>
    </a-button-group>
  </div>
</template>

<script>
export default {
  props: ["index", "item"],
  data() {
    return {};
  },
  methods: {
    removeItem() {
      this.$emit("remove", this.index);
    },
  },
};
</script>

<style scoped></style>
